<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="grid">
        <div class="field col-12">
          {{form}}
          <label>{{form.description}}</label>
          <div class="p-inputgroup flex-1" v-if="form.type == 'number'">
            <span class="p-inputgroup-addon"> Rp </span>
            <InputNumber
              ref="val"
              v-model="form.value"
              locale="id-ID"
              class="w-full"
              input-class="w-full"
              :min="0"
              :minFractionDigits="0"
              :maxFractionDigits="0"
              @focus="selectAllText('val')"
            />
          </div>
          <div class="p-inputgroup flex-1" v-else>
            <span class="p-inputgroup-addon"> Rp </span>
            <InputText
                v-model="form.value"
                class="w-full"
                input-class="w-full"
                maxlength="10"
                :class="{
                  'p-invalid': v$.form.value.$invalid && submitted,
                }"
              />
          </div>
          <small
            v-if="
              (v$.form.value.$invalid && submitted) ||
              v$.form.value.$pending.$response
            "
            class="p-error"
            >{{ v$.form.value.required.$message }}</small
          >
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="$emit('close')"
          class="p-button-text mr-2"
        />
        <Button
          :loading="loading"
          label="Simpan"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required, numeric } from '@vuelidate/validators'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
    cabang: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      submitted: false,
    }
  },
  computed: {
    form() {
      return this.item
    },
  },
  methods: {
    selectAllText(event) {
      if (event) {
        this.$refs[event].$el.querySelector('input').select()
      }
    },
    handleSubmit(isFormValid) {
      this.submitted = true

      if (
        this.editMode == true &&
        (this.form.div == '' || this.form.div == null)
      ) {
        return
      }

      if (!isFormValid) {
        return
      }

      this.$emit('save', this.form)
    },
  },
  validations() {
    return {
      form: {
        value: {
          required: helpers.withMessage(
            'Nilai harus diisi angka numerik.',
            required
          ),
          numeric,
        },
      },
    }
  },
}
</script>
